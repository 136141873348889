import { isString } from 'lodash-es';

interface IActiveRequestInfo {
  id: string;
  info: RequestInfo;
  init: RequestInit;
  controller?: AbortController;
}

export const activeRequests: Record<string, IActiveRequestInfo> = {};

export const addActiveRequest = (activeRequestInfo: IActiveRequestInfo): void => {
  const isWindowDefined = typeof window !== 'undefined';
  if (isWindowDefined && ((typeof window.Request === 'function'
    && window.Request.prototype.hasOwnProperty('signal'))
    || window.AbortController)) {
    activeRequestInfo.controller = new AbortController();
    activeRequestInfo.init.signal = activeRequestInfo.controller.signal;
  }
  activeRequests[activeRequestInfo.id] = activeRequestInfo;
};
export const removeActiveRequest = (activeRequestInfo: IActiveRequestInfo): void => {
  delete activeRequests[activeRequestInfo.id];
};

const getRequestUrl = (activeRequestInfo: IActiveRequestInfo) => {
  return isString(activeRequestInfo.info)
    ? activeRequestInfo.info
    : (activeRequestInfo.info as Request).url;
};

export const abortRequests = (url: string, method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'HEAD' | 'CONNECT' | 'TRACE'): void =>
  Object.values(activeRequests)
    .filter((entry) =>
      (!method || entry.init.method === method)
      && getRequestUrl(entry)
        .toLowerCase()
        .startsWith(url.toLowerCase()))
    .forEach((activeRequestsInfo) => activeRequestsInfo.controller?.abort());
