import { ToastMessage } from 'components/common/ToastMessage';
import React from 'react';
import { toast } from 'react-toastify';

const toastError = (message?: string): void => {
  const messageOrDefault = message || 'An error has occured, please retry later!';
  toast.error(
    <ToastMessage message={messageOrDefault} />,
    { toastId: messageOrDefault },
  );
};

const toastErrorFromServer = async (response: Response) => {
  try {
    const serverErrorAsText = await response.text();
    const { message }: { message: string } = JSON.parse(serverErrorAsText);
    toastError(message);
    throw Error(serverErrorAsText);
  }
  catch {
    toastError();
  }
};

export const processResponse = async (response: Response): Promise<Response> => {
  const { status } = response;
  if (status === 401) {
    toastError('You are not authenticated');
  } else if (status === 403) {
    toastError('You are not authorized to perform this action');
  } else if (status === 404) {
    toastError('Not found');
  } else if (status !== 200 && status !== 204 && status !== 400) {
    await toastErrorFromServer(response);
  }
  return Promise.resolve(response);
};
