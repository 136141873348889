import { createSlice } from '@reduxjs/toolkit';
import { PublicationType } from 'services/ApiService';


export interface SearchState {
  quickSearch?: string;
  publicationType?: PublicationType;
  page: number,
  totalResults?: number,
}

const initialState: SearchState = {
  page: 1,
};

export const searchSlice = createSlice({
  name: '@SEARCH',
  initialState,
  reducers: {
    setQuickSearch: (state, { payload }: { payload: string }) => {
      state.quickSearch = payload;
      state.page = 1;
    },
    setPublicationType: (state, { payload }: { payload: PublicationType | undefined }) => {
      state.publicationType = payload;
      state.page = 1;
    },
    setPage: (state, { payload }: { payload: number }) => {
      state.page = payload;
    },
    setTotalResults: (state, { payload }: { payload: number }) => {
      state.totalResults = payload;
    },
  },
});
