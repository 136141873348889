import React from 'react';
import { createRoot } from 'react-dom/client';
import { initSGConnect } from './services/sgConnect';
import { App } from './components/App';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie11';

const renderApp = () => {
  const container = document.getElementById('root');

   
  const root = createRoot(container!);
  root.render(<App />);
  const appInitLoader = document.getElementById('app-init-loader');
  if (appInitLoader) {
      appInitLoader.remove();
  }
};

initSGConnect();

renderApp();
