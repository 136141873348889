import classNames from 'classnames';
import React from 'react';
import { FC } from 'react';

export interface ILoadingProps {
  text?: string;
  className?: string;
  visible?: boolean;
  useChildrenAsText?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  spinnerOnly?: boolean;
}

export const Loading: FC<ILoadingProps> = ({
  text = 'Loading...',
  className,
  style,
  visible = true,
  size,
  children,
  useChildrenAsText = false,
  spinnerOnly = false,
}) => {
  if (!visible) {
    return <>{children}</>;
  }

  return <div className={classNames('position-relative', className)} style={style}>
    {!spinnerOnly && useChildrenAsText ? (<span>{children}</span>) : null}
    {!spinnerOnly && !useChildrenAsText ? (<span>{text}</span>) : null}
    <div
      className={classNames('blinker me-2 position-absolute', {
        [`blinker-${size}`]: size,
        'mt-2': size === 'sm' || size === 'md',
        'mt-1': size === 'lg' || size === 'xl',
      })}
      style={{ left: 0 }}
      role="status"
      aria-hidden="true"
    />
  </div>;
};

export interface IServiceLoaderProps {
  visible?: boolean;
  children?: React.ReactNode;
  light?: boolean;
}

export const ServiceLoader: FC<IServiceLoaderProps> = ({ visible = true, children, light = false }) => {
  if (!visible) {
    return children;
  }

  if (light) {
    return <div className="service-loader d-flex flex-column position-absolute">
      <div className="spinner spinner-lg"></div>
    </div>;
  }

  return <div className="service-loader d-flex flex-column position-absolute">
    <div className="service-loader-logo"></div>
    <div className="progress progress-sm progress-container">
      <div className="progress-bar animated-progress" role="progressbar" />
    </div>
  </div>;
};

interface LoadingOverlayProps {
  text?: string;
  visible?: boolean;
  children?: React.ReactNode;
}

export const LoadingOverlay: FC<LoadingOverlayProps> = ({
  children,
  text = 'Loading...',
  visible = true,
}) => {
  const style: React.CSSProperties = visible ?
    {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      display: 'flex',
      background: 'rgba(255, 255, 255, 0.9)',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 21,
    } : {
      display: 'none',
    };
  return (
    <>
      <div style={style} role="status" >
        <div className="blinker">
          {text}
        </div>
      </div>
      {children}
    </>
  );
};