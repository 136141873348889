import * as React from 'react';
import { ToastContentProps } from 'react-toastify';
import { formatDatetime } from 'services/DateUtilities';

export type ToastMessageProps = Partial<ToastContentProps> & {
    title?: string | null;
    message?: string | null;
    children?: React.ReactNode;
};

export const ToastMessage: React.FC<ToastMessageProps> = ({ title, message, children, closeToast, toastProps }) => {
    let toastTitle = title;
    if (!title) {
        switch (toastProps?.type) {
            case 'error':
                toastTitle = 'Error';
                break;

            case 'warning':
                toastTitle = 'Warning';
                break;

            case 'success':
                toastTitle = 'Success';
                break;

            default:
                toastTitle = 'Information';
                break;
        }
    }

    return (
        <>
            <div className="notification-header">
                <span className="fw-medium me-auto">{toastTitle}</span>
                <span className="notification-timestamp">
                    {formatDatetime(new Date())}
                </span>
                <button type="button" className="btn-close" aria-label="Close" onClick={closeToast} />
            </div>
            {message || children
                ? <div
                    className="notification-body text-primary"
                    style={{ whiteSpace: 'pre-line' }}
                >
                    {message && <div>{message}</div>}
                    {children && <div>{children}</div>}
                </div>
                : null}
        </>
    );
};
