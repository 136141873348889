import { ClientLogError, LogsApi } from 'services/ApiService';
import { ApiException } from 'services/ApiService/Http';
import { getAuthorizationHeader, initSGConnect } from './sgConnect';


export let lastError: Error | undefined = undefined;

export const logError = (error: Error | unknown): Promise<void> => {
  if (error instanceof ApiException) {
      switch (error.status) {
          case 401:
              initSGConnect();
              return Promise.resolve();
      }
      return Promise.resolve();
  }

  if (error instanceof Error && error.name !== 'AbortError' && error.message !== 'Failed to fetch') {
      if (lastError?.message !== error.message) {
          lastError = {
              message: error.message,
              name: error.name,
              stack: error.stack,
          };
          const isDocumentDefined = typeof document !== 'undefined';
          const log: ClientLogError = {
              message: error.message,
              name: error.name,
              stackTrace: error.stack,
              pageUrl: isDocumentDefined ? document.location?.pathname : undefined,
          };

          if (getAuthorizationHeader()) {
              LogsApi.error(log)
                  .catch((err) => {
                      throw err;
                  });
          }
      }
  }

  return Promise.resolve();
};
