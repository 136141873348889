import { createSlice } from '@reduxjs/toolkit';
import { handleThunk } from 'store/helpers/reducerHandlers';
import { WithRequestState } from 'store/helpers/requestState';
import { translatePodcastPublication } from './thunks/translatePublication';
import { uploadFile } from 'store/file/thunks/uploadFile';

const initialState: WithRequestState = {
  requestState: 'idle',
};

export const translatePublicationSlice = createSlice({
  name: '@TRANSLATE_PUBLICATION',
  initialState,
  reducers: {},
  extraReducers: builder => {
    handleThunk(builder, translatePodcastPublication);
    handleThunk(builder, uploadFile);
  },
});