import { ServiceLoader } from 'components/common/Loading';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { convertUserPermissions, hasAccess, hasAnyRight, hasRight, Permissions } from 'services/RightsService';
import { sgConnect, redirectUnauthorizedUser } from 'services/sgConnect';
import { RootState } from 'store';
import { useUserPrivileges } from 'store/user';

export const ProtectedRoute: React.FC<{
  children: JSX.Element,
  permission?: Permissions,
  anyPermissions?: Permissions[],
}> = ({ children, permission, anyPermissions }) => {
  const loading = useSelector<RootState, boolean>((x) => x.user.requestState !== 'fulfilled');
  const privileges = convertUserPermissions(useUserPrivileges());

  useEffect(() => {
    (async () => {
      if (!sgConnect?.isAuthorized()) {
        sgConnect?.requestAuthorization();
      }
      else {
        if (privileges && !privileges.length && !loading) {
          redirectUnauthorizedUser();
        }
      }
    })();
  }, [privileges]);

  if (!privileges) {
    return <div className="d-flex justify-content-center"><ServiceLoader /></div>;
  }

  return hasAccess(privileges)
    && (!permission || hasRight(privileges, permission))
    && (!anyPermissions?.length || hasAnyRight(privileges, anyPermissions))
    ? children
    : null;
};
