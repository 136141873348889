import React, { FC } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { enUS } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#6e777a',
        },
    },
    transitions: { create: () => 'none' },
});

export const MaterialProvider: FC<{ children?: React.ReactNode }> = ({ children }) => (
  <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={enUS}>
          {children}
      </LocalizationProvider>
  </ThemeProvider>
);
