import { createAsyncThunk } from '@reduxjs/toolkit';
import { FilesApi, FileType } from 'services/ApiService';

export const uploadFile = createAsyncThunk(
  '@CREATE_FILE',
  async (fileInfo: { file: File, fileType: FileType }) => {
    if (!fileInfo || !fileInfo.file || !fileInfo.fileType) {
      throw Error('fileInfo is not properly filled');
    }

    const fileUpdloadInfo = await FilesApi.upload(fileInfo.fileType, { data: fileInfo.file, fileName: fileInfo.file.name });
    if (!fileUpdloadInfo?.id) {
      throw Error('expected to receive an id on successfull file upload');
    }

    return fileUpdloadInfo.id;
  },
);
