import 'isomorphic-fetch';
import { uniqueId } from 'lodash-es';
import { toast } from 'react-toastify';
import { configuration } from '../../config/constants';
import { getAuthorizationHeader } from '../sgConnect';
import { processResponse } from './errorHandler';
import { addActiveRequest, removeActiveRequest } from './requestWatcher';
import { ToastMessage } from 'components/common/ToastMessage';
import React from 'react';

interface IErrorData {
  code: string;
  message: string;
  detailedMessage?: string;
  stackTrace?: string;
}

interface IValidationError {
  code: string;
  message: string;
  target: string;
  details: IValidationError[];
}

export class ApiException extends Error {
  public message: string;
  public status: number;
  public data?: IErrorData | IValidationError | null;

  constructor(message: string, status: number, data?: IErrorData | IValidationError | null) {
    const trueProto = new.target.prototype;
    super();

    this.message = message;
    this.status = status;
    this.data = data;

    Object.setPrototypeOf(this, trueProto);
  }
}

export const isWarning = (error: Error | ApiException): boolean => {
  const apiException = error as ApiException;
  return apiException?.data?.code === 'WARN';
};

export const buildErrorMessage = (error: Error | ApiException) => {
  let message = null;
  if (error.name !== 'AbortError') {
    message = error.message;
    const apiException = error as ApiException;
    if (apiException && apiException.data) {
      const unknownError = apiException.data as IErrorData;
      const validationError = apiException.data as IValidationError;
      if (unknownError && unknownError.detailedMessage) {
        message += `\n${unknownError.detailedMessage}`;
      }
      if (validationError && validationError.details && validationError.details.length) {
        const errorDetailsSeparator = '\n\↪ ';
        message += errorDetailsSeparator + validationError.details.map((d) => d.message).join(errorDetailsSeparator);
      }
    }
    if (apiException.status === 404) {
      message = null;
    }
  }
  return message;
};

export const manageHttpError = (error: Error | ApiException): never => {

  const errorMessage = buildErrorMessage(error);
  if (errorMessage) {
    if (isWarning(error)) {
      toast.warning(
        <ToastMessage message={errorMessage} />,
        { toastId: errorMessage },
      );
    }
    else {
      toast.error(
        <ToastMessage message={errorMessage} />,
        { toastId: errorMessage },
      );
    }
  }
  throw error;
};

interface IActiveRequestInfo {
  id: string;
  info: RequestInfo;
  init: RequestInit;
}

export const http = {
  baseUrl: configuration.baseApiUrls.publicInsight,
  fetch: async (url: RequestInfo, requestInit?: RequestInit): Promise<Response> => {
    const init = requestInit || {};
    init.headers = new Headers(init.headers);
    init.headers.append('Cache-Control', 'no-cache, no-store');
    init.headers.append('Pragma', 'no-cache');
    init.cache = 'no-store';
    init.credentials = 'omit';

    const authorizationHeader = getAuthorizationHeader();
    if (authorizationHeader) {
      init.headers.append('Authorization', authorizationHeader);
    }

    const activeRequestInfo: IActiveRequestInfo = {
      id: uniqueId(),
      info: url,
      init,
    };

    addActiveRequest(activeRequestInfo);

    try {
      const response = await fetch(url, init);
      return await processResponse(response);
    } finally {
      removeActiveRequest(activeRequestInfo);
    }
  },
};

export default http;
