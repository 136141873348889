import { PublicationFormValues } from 'components/common/PublicationForm';
import {
  CreatePublicationInsightRequest,
  CreatePublicationNewsRequest,
  CreatePublicationPodcastRequest,
  CreatePublicationVideoRequest,
  CreateAlternativeViewsRequest,
  CreateQuantMotionPictureRequest,
  InsightPublicationsApi,
  NewsPublicationsApi,
  PodcastPublicationsApi,
  PublicationType,
  VideoPublicationsApi,
  AlternativeViewsPublicationsApi,
  QuantMotionPicturePublicationsApi,
} from 'services/ApiService';
import * as PublicationFormMapping from 'store/mapping/mapPublicationFormValues';
import { createAsyncThunkWithErrorPayload } from 'store/helpers';

export const createVideoPublication = createAsyncThunkWithErrorPayload('@CREATE_VIDEO_PUBLICATION', (createPublicationRequest: CreatePublicationVideoRequest) =>
  VideoPublicationsApi.createVideoPublication(createPublicationRequest));

export const createInsightPublication = createAsyncThunkWithErrorPayload('@CREATE_INSIGHT_PUBLICATION', (createPublicationRequest: CreatePublicationInsightRequest) =>
  InsightPublicationsApi.createInsightPublication(createPublicationRequest));

export const createNewsPublication = createAsyncThunkWithErrorPayload('@CREATE_NEWS_PUBLICATION', (createPublicationRequest: CreatePublicationNewsRequest) =>
  NewsPublicationsApi.createNewsPublication(createPublicationRequest));

export const createPodcastPublication = createAsyncThunkWithErrorPayload('@CREATE_PODCAST_PUBLICATION', (createPublicationRequest: CreatePublicationPodcastRequest) =>
  PodcastPublicationsApi.createPodcastPublication(createPublicationRequest));

export const createAlternativeViewPublication = createAsyncThunkWithErrorPayload('@CREATE_ALTERNATIVE_VIEW_PUBLICATION', (createAlternativeViewsRequest: CreateAlternativeViewsRequest) =>
  AlternativeViewsPublicationsApi.createAlternativeViewsPublication(createAlternativeViewsRequest));

export const createQuantMotionPicturePublication = createAsyncThunkWithErrorPayload('@CREATE_QUANT_MOTION_PICTURE_PUBLICATION', (createQuantMotionPictureRequest: CreateQuantMotionPictureRequest) =>
QuantMotionPicturePublicationsApi.createQuantMotionPicturePublication(createQuantMotionPictureRequest));

export const createPublication = (formValues: PublicationFormValues, coverImageFileId: number | undefined, transcriptFileId: number | undefined, quantImageFileId: number) => {
  const thunkFactory = {
    [PublicationType.News]: () => createNewsPublication(
      PublicationFormMapping.getCreateNewsPublicationRequest(formValues, coverImageFileId),
    ),
    [PublicationType.Insight]: () => createInsightPublication(
      PublicationFormMapping.getCreateInsightPublicationRequest(formValues, coverImageFileId),
    ),
    [PublicationType.Video]: () => createVideoPublication(
      PublicationFormMapping.getCreateVideoPublicationRequest(formValues, coverImageFileId),
    ),
    [PublicationType.Podcast]: () => createPodcastPublication(
      PublicationFormMapping.getCreatePodcastPublicationRequest(formValues, coverImageFileId, transcriptFileId),
    ),
    [PublicationType.AlternativeView]: () => createAlternativeViewPublication(
      PublicationFormMapping.getCreateAlternativeViewPublicationRequest(formValues, coverImageFileId),
    ),
    [PublicationType.QuantMotionPicture]: () => createQuantMotionPicturePublication(
      PublicationFormMapping.getCreateQuantMotionPicturePublicationRequest(formValues, coverImageFileId, quantImageFileId),
    ),
  }[formValues.publicationType];
  return thunkFactory();
};