import { Environment, getEnvironment } from 'config/constants';

export type SgConnectEnvironment = 'production' | 'homologation';

export interface SgConnectSettings {
  authorizationEndpoint: string;
  clientId: string;
  scope: string;
  environment: SgConnectEnvironment;
}

export const getSGConnectSettings = (): SgConnectSettings => {
  const websiteEnv = getEnvironment();
  const sgConnectEnv: SgConnectEnvironment = websiteEnv === Environment.Production
    ? 'production'
    : 'homologation';

  const scope = 'mail openid profile api.sg-markets-public-insight-backoffice.v1';

  const baseSettings = {
    scope,
    environment: sgConnectEnv,
  };

  if (sgConnectEnv === 'production') {
    return {
      clientId: '99f49fff-5d3d-4915-a55b-5ac8ca79d697',
      authorizationEndpoint: 'https://sso.sgmarkets.com:443/sgconnect/oauth2/authorize',
      ...baseSettings,
    };
  }
  return {
    clientId: '3d3340aa-80e1-447d-90fc-b3bcfac402b5',
    authorizationEndpoint: 'https://sgconnect-hom.fr.world.socgen/sgconnect',
    ...baseSettings,
  };
};