export enum Environment {
  Development = 'Development',
  Homologation = 'Homologation',
  Production = 'Production',
  Test = 'Test'
}

export const getEnvironment = () => {
  let environment = Environment.Development;
  if (typeof window === 'undefined' || !window.location.hostname) {
    environment = Environment.Test;
  } else if (window.location.hostname === 'local.fr.world.socgen'
    || window.location.hostname === 'localhost') {
    environment = Environment.Development;
  } else if (window.location.hostname === 'insight-public-admin-dev.sgmarkets.com'
    || window.location.hostname === 'insight-public-admin-dev.azureedge.net') {
    environment = Environment.Development;
  } else if (window.location.hostname === 'insight-public-admin-uat.sgmarkets.com'
    || window.location.hostname === 'insight-public-admin-uat.azureedge.cnet') {
    environment = Environment.Homologation;
  } else if (window.location.hostname === 'insight-public-admin.sgmarkets.com'
    || window.location.hostname === 'insight-public-admin-prd.azureedge.net'
    || window.location.hostname === 'insight-public-admin-preview.sgmarkets.com'
    || window.location.hostname === 'insight-public-admin-preview.azureedge.net') {
    environment = Environment.Production;
  }

  return environment;
};

const defaultConfiguration = {
  getPublicationPageSize: 100,
  maxRank: 6,
  widgetCdnBaseUrl: 'https://sgwt-cdn-widgets.sgmarkets.com',
  sharedSgMarketsBaseUrl: 'https://shared.sgmarkets.com',
};

type Configuration = {
  requestAccessUrl: string;
  baseApiUrls: {
    publicInsight: string;
  }

} & typeof defaultConfiguration;

export const configurationPerEnvironment: Record<Environment, Configuration> = {
  [Environment.Development]: {
    ...defaultConfiguration,
    baseApiUrls: {
      publicInsight: 'https://sgmarkets-api-public-insight-dev.sgmpinsight.dev.euw.gbis.sg-azure.com',
    },
    requestAccessUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://insight-public-admin-dev.sgmarkets.com',
  },
  [Environment.Homologation]: {
    ...defaultConfiguration,
    baseApiUrls: {
      publicInsight: 'https://sgmarkets-api-public-insight-uat.sgmpinsight.dev.euw.gbis.sg-azure.com',
    },
    requestAccessUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://insight-public-admin-uat.sgmarkets.com',
  },
  [Environment.Production]: {
    ...defaultConfiguration,
    baseApiUrls: {
      publicInsight: 'https://sgmarkets-api-public-insight-prd.sgmpinsight.prd.euw.gbis.sg-azure.com',
    },
    requestAccessUrl: 'https://shared.sgmarkets.com/unauthorized/?redirectUri=https://insight-public-admin.sgmarkets.com',
  },
  [Environment.Test]: {
    ...defaultConfiguration,
    baseApiUrls: {
      publicInsight: 'https://sgmarkets-api-public-insight-test.test.com',
    },
    requestAccessUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://insight-public-admin-uat.sgmarkets.com',
  },
};

export const configuration = configurationPerEnvironment[getEnvironment()];
