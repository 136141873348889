import {
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { FilesApi } from 'services/ApiService';

export const getFileInformation = createAsyncThunk(
  '@FILE/FETCH_INFO',
  async (id: number) => {
    const result = await FilesApi.information(id);
    return result;
  },
);