import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';
import { configuration, getEnvironment } from 'config/constants';
import * as React from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'sgwt-account-center': {
        id?: string;
        'available-languages'?: string;
        authentication?: string;
        environment?: string;
        language?: string;
        mode?: 'sg-markets';
        debug?: string;
        ref?: (elt: HTMLElement) => void;
      };
    }
  }

  interface DocumentEventMap {
    [signOutEventName]: MouseEvent;
  }
}

const signOutEventName = 'sgwt-account-center--sign-out';

export const SgwtAccountCenter: React.FC = () => {
  const environment = getEnvironment();
  return <WidgetLazyLoader script={`${configuration.widgetCdnBaseUrl}/widgets/sgwt-account-center/v4/sgwt-account-center.js`}>
    <sgwt-account-center
      id="sgwtAccountCenter"
      language="en"
      available-languages="en"
      authentication="sg-connect-v2"
      mode="sg-markets"
      producer-code="insight-public-backoffice"
      environment={environment?.toUpperCase() === 'PRODUCTION' ? undefined : environment || undefined}
    />
  </WidgetLazyLoader>;
};