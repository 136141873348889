import {
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import { Person } from 'services/ApiService';
import { RequestStateById } from 'store/helpers';
import { handleGetEntityByIdThunk } from 'store/helpers/reducerHandlers';
import { getPerson } from './thunks/getPerson';

export const personAdapter = createEntityAdapter<Person>({
  selectId: (pub) => pub.icId,
  sortComparer: (x, y) => x.lastName?.localeCompare(y.lastName ?? '') ?? -1,
});

export const personSlice = createSlice({
  name: '@PERSON',
  initialState: personAdapter.getInitialState({
    requestStateById: {} as RequestStateById<string>,
  }),
  reducers: {},
  extraReducers: builder => {
    handleGetEntityByIdThunk(builder, personAdapter, getPerson);
  },
});