import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import { MaterialProvider } from 'providers/MaterialProvider';
import { AppRoutes } from '../routes';
import { ErrorBoundary } from './common/ErrorBoundary';

import 'bootstrap';
import 'css/bootstrapTheming.scss';
import 'css/site.scss';

export const AppProvider: FC<{ children: React.ReactNode }> = ({ children }) => <Provider store={store}>{children}</Provider>;

export const App = () => (
  <AppProvider>
    <ErrorBoundary>
      <MaterialProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </MaterialProvider>
    </ErrorBoundary>
  </AppProvider>
);


