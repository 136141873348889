import {
  createSlice,
} from '@reduxjs/toolkit';
import { handleThunk } from 'store/helpers/reducerHandlers';
import { WithRequestState } from 'store/helpers/requestState';
import { getUserPrivileges } from './thunks/getUserPrivileges';

const initialState: WithRequestState<{
  readonly privileges?: string[]
}> = {
  privileges: undefined,
  requestState: 'idle',
};

export const userSlice = createSlice({
  name: '@USER',
  initialState,
  reducers: {},
  extraReducers: builder => {
    handleThunk(
      builder, getUserPrivileges, (state, { payload }) => {
        state.privileges = payload;
      });
  },
});