import {
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { CategoryApi, PublicationCategory } from 'services/ApiService';
import { ThunkApi } from '../..';

export const getCategories = createAsyncThunk<PublicationCategory[], void, ThunkApi>(
  '@CATEGORY/FETCH_ALL',
  async () => {
    const results = (await CategoryApi.getCategories());
    return results.values ?? [];
  }, {
    condition: (_,{ getState }) => {
      const currentState = getState().categories.requestState;
      if (currentState === 'pending' || currentState === 'fulfilled')
        return false;
    },
  });