export enum Permissions {
  Admin = 'Admin',
  AccessToTools = 'Access to Tools',
  UploadFile = 'Upload file',
}

export const convertUserPermissions = (userPermissions?: string[] | null): Permissions[] => {
  if (!userPermissions?.length) {
    return [];
  }

  return userPermissions.map((up) => up as Permissions);
};

export const hasRight = (userPermissions?: Permissions[] | null, permission?: Permissions): boolean => {
  if (userPermissions && userPermissions.length) {
    return !!userPermissions.find((up) => up === permission);
  }
  return false;
};

export const hasAnyRight = (userPermissions: Permissions[] | null | undefined, permissions: Permissions[]): boolean => {
  if (userPermissions && userPermissions.length) {
    return !!userPermissions.some((up) => permissions.includes(up));
  }
  return false;
};

export const hasAccess = (userPermissions: Permissions[] | null | undefined) => {
  return hasRight(userPermissions, Permissions.Admin);
};