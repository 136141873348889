import { createAsyncThunk } from '@reduxjs/toolkit';
import { PersonsApi, Person } from 'services/ApiService';
import { ThunkApi } from '../..';

export const getPerson = createAsyncThunk<Person, string, ThunkApi>(
  '@PERSON/FETCH',
  async (id: string) => {
    const result = await PersonsApi.getPerson(id);
    return result;
  }, {
  condition: (id, { getState }) => {
    const currentState = getState().person.requestStateById[id];
    if (currentState === 'pending' || currentState === 'fulfilled') {
      return false;
    }
  },
});