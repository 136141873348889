import React from 'react';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { Header } from './Header';
import { HelpCenter } from './HelpCenter';
import { Footer } from './Footer';
import { SplashScreen } from './SplashScreen';
import { SgwtConnect } from './SgwtConnect';
import 'react-toastify/dist/ReactToastify.css';
import classNames from 'classnames';

interface IProps {
  children: React.ReactNode;
}

export class Layout extends React.PureComponent<IProps> {
  public render() {
    const { children } = this.props;
    return (
      <>
        <Header />
        <main className="bg-lvl2" style={{ minHeight: 'calc(100vh - var(--header-height))' }}>
          <ErrorBoundary>
            {children}
          </ErrorBoundary>
        </main>
        <Footer />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          closeButton={false}
          icon={false}
          newestOnTop
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          stacked
          toastClassName={context => classNames(
              'toast fade show',
              {
                  'toast-danger': context?.type === 'error',
                  'toast-info': context?.type === 'info',
                  'toast-success': context?.type === 'success',
                  'toast-warning': context?.type === 'warning',
                  'toast-primary': context?.type === 'default',
              },
          )}
          bodyClassName={() => ''}
          progressClassName={context => classNames(
              context?.defaultClassName,
              {
                  'bg-danger': context?.type === 'error',
                  'bg-info': context?.type === 'info',
                  'bg-success': context?.type === 'success',
                  'bg-warning': context?.type === 'warning',
                  'bg-primary': context?.type === 'default',
              },
          )}
          progressStyle={{ height: '3px' }}
        />
        <HelpCenter />
        <SgwtConnect />
        <SplashScreen />
      </>
    );
  }
}