import React from 'react';
import { logError } from 'services/Logger';
import { NonProduction } from '../NonProduction';

interface ErrorBoundaryState {
  error: Error | null;
}

export class ErrorBoundary extends React.PureComponent<any, ErrorBoundaryState> {
  public static getDerivedStateFromError(error: Error | null): ErrorBoundaryState {
    return { error };
  }

  public state = ErrorBoundary.getDerivedStateFromError(null);

  public componentDidCatch(error: Error | null): void {
    this.setState({ error });
    if (error) {
      logError(error);
    }
  }

  public render() {
    const { children } = this.props;
    const { error } = this.state;
    if (error) {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="alert alert-outline-warning" role="alert">
            An unknown error has occured. Please contact {' '}
            <a href="mailto:sgmarkets@sgcib.com" className="alert-link">
              sgmarkets@sgcib.com
            </a>
          </div>
          <button type="button" className="btn btn-primary" onClick={() => this.setState({ error: null })}>
            Reload
          </button>
          <NonProduction>
            <div>
              <div>
                {error?.name}
              </div>
              <div>
                {error?.message}
              </div>
              <pre>
                {JSON.stringify(error?.stack).replace(/\\n/g, '\n')}
              </pre>
            </div>
          </NonProduction>
        </div>
      );
    }

    return children;
  }
}
