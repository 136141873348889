import classNames from 'classnames';
import sgLogo from 'images/SGLogo.svg';
import SgMarketLogo from 'images/sg_market.svg';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { RoutePaths } from 'routes/RoutePaths';
import { SgwtAccountCenter } from './SgwtAccountCenter';

export const Header: React.FC = () => {
  return <header className="header position-relative">
    <nav className="navbar border-bottom">
      <div className="navbar-title">
        <NavLink
          to={RoutePaths.Home.url()}
          className={({ isActive }) => classNames('navbar-title-link', { active: isActive })}
        >
          <img src={sgLogo} width="32" height="32" alt="SG logo" />
          <div className="navbar-title-divider" />
          <div className="navbar-service-name text-nowrap">Insight public admin</div>
        </NavLink>

      </div>
      <div className="navbar-content"/>
      <div className="navbar-toolbar">
        <SgwtAccountCenter />

        <svg className="d-none d-md-block overflow-visible ms-3" width="145" height="32">
          <image xlinkHref={SgMarketLogo} x="0" y="0" height="16" width="145"></image>
          <text className="font-weight-medium" x="5" y="32" height="16" width="145">
            Research &amp; Insight
          </text>
        </svg>
      </div>
    </nav>
  </header>;
};
