import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { FileInformation } from 'services/ApiService';
import { RequestState, RequestStateById } from 'store/helpers';
import { handleGetEntityByIdThunk, handleThunk } from 'store/helpers/reducerHandlers';
import { getFileInformation } from './thunks/getFileInformation';
import { uploadFile } from './thunks/uploadFile';

export const fileInfoAdapter = createEntityAdapter<FileInformation>({
  selectId: (file) => file.id,
});

export const fileInfoSlice = createSlice({
  name: 'fileInfo',
  initialState: fileInfoAdapter.getInitialState({
    requestStateById: {} as RequestStateById<number>,
    requestState: 'idle' as RequestState,
  }),
  reducers: {},
  extraReducers: builder => {
    handleGetEntityByIdThunk(builder, fileInfoAdapter, getFileInformation);
    handleThunk(builder, uploadFile);
  },
});
