import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { createPublicationSlice } from './createPublication';
import { userSlice } from './user';
import { publicationSlice } from './publications';
import { searchSlice } from './search';
import { personSlice } from './person';
import { editPublicationSlice } from './editPublication';
import { fileInfoSlice } from './file/fileSlice';
import { translatePublicationSlice } from './translatePublication';
import { categoriesSlice } from './category/categoriesSlice';

export const rootReducer = combineReducers({
  publications: publicationSlice.reducer,
  search: searchSlice.reducer,
  user: userSlice.reducer,
  person: personSlice.reducer,
  createPublication: createPublicationSlice.reducer,
  translatePublication: translatePublicationSlice.reducer,
  editPublication: editPublicationSlice.reducer,
  fileInfo: fileInfoSlice.reducer,
  categories: categoriesSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export const middleware = undefined;

export const createStore = (preloadedState?: Partial<RootState>) => configureStore({
  reducer: rootReducer,
  preloadedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export type ThunkApi = { state: RootState, dispatch: AppDispatch };

export const useAppDispatch = () => useDispatch<AppDispatch>();