import { createAsyncThunk } from '@reduxjs/toolkit';
import { SwaggerException, ValidationError } from 'services/ApiService';
import { ThunkApi } from 'store';

// just adds a ValidationError to the thunk.rejected action to recuperate validation errors
export const createAsyncThunkWithErrorPayload = <TReturned, TThunkArg>(typePrefix: string, thunk: (a: TThunkArg) => Promise<TReturned>) => {
  return createAsyncThunk<TReturned, TThunkArg, ThunkApi>(typePrefix, async (arg, { rejectWithValue }) => {
    try {
      const value = await thunk(arg);
      return value;
    } catch (e) {
      if (SwaggerException.isSwaggerException(e) && e.status === 400) {
        return rejectWithValue(JSON.parse(e.response) as ValidationError);
      }
      throw e;
    }
  });
};