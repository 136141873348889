import {
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { PublicationSummary, PublicationSummaryApi } from 'services/ApiService';
import { ThunkApi } from '../..';
import { configuration } from '../../../config/constants';
import { searchSlice } from '../../search';

export const getAllPublications = createAsyncThunk<PublicationSummary[], void, ThunkApi>(
  '@PUBLICATIONS/FETCH_ALL',
  async (_, { getState, dispatch }) => {
    const state = getState();
    const { quickSearch, publicationType, page } = state.search;
    const { getPublicationPageSize } = configuration;
    const pagedResponse = (await PublicationSummaryApi.search(
      publicationType,
      quickSearch,
      (page - 1) * getPublicationPageSize,
      getPublicationPageSize));
    dispatch(searchSlice.actions.setTotalResults(pagedResponse.totalResults ?? 0));
    return pagedResponse.results ?? [];
  },
);

export const getPublicationsByIds = createAsyncThunk(
  '@PUBLICATIONS/FETCH_BY_IDS',
  async (ids:number[]) => {
    const pubs = (await PublicationSummaryApi.doGetSummaries({publicationIds: ids}));
    return pubs.publications ?? [];
  },
);