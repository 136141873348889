import {
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { AppContextsApi } from 'services/ApiService';

export const getUserPrivileges = createAsyncThunk(
  '@USER/FETCH_PRIVILEGES',
  async () => {
    const result = (await AppContextsApi.get());
    return result?.loggedUser?.privileges ?? [];
  },
);