import { generatePath } from 'react-router-dom';
import { PublicationType } from 'services/ApiService';

export const RoutePaths = {
  Home: {
    route: '/',
    url: () => RoutePaths.Home.route,
    Preview: {
      route: '/view/:publicationType/:id',
      url: (publicationId: number, publicationType: PublicationType) => generatePath(RoutePaths.Home.Preview.route,
        { id: `${publicationId}`, publicationType: `${publicationType}` }),
    },
  },
  CreatePublication: {
    route: '/create',
    url: () => RoutePaths.CreatePublication.route,
  },
  TranslatePublication: {
    route: '/translate/:publicationType/:id',
    url: (publicationId: number, publicationType: PublicationType) => generatePath(RoutePaths.TranslatePublication.route,
      { id: `${publicationId}`, publicationType: `${publicationType}` }),
  },
};

export type RouteParams = {
  Home: {
    Preview: ExtractRouteParams<typeof RoutePaths.Home.Preview.route, string>,
  },
  CreatePublication: ExtractRouteParams<typeof RoutePaths.CreatePublication.route, string>,
  TranslatePublication: ExtractRouteParams<typeof RoutePaths.TranslatePublication.route, string>,
};


type ExtractRouteOptionalParam<T extends string, U = string | number | boolean> = T extends `${infer Param}?`
  ? { [_ in Param]?: U }
  : T extends `${infer Param}*`
  ? { [_ in Param]?: U }
  : T extends `${infer Param}+`
  ? { [_ in Param]: U }
  : { [_ in T]: U };

/* eslint-disable */
type ExtractRouteParams<T extends string, U = string | number | boolean> = string extends T
  ? { [_ in string]?: U }
  : T extends `${infer _}:${infer ParamWithOptionalRegExp}/${infer Rest}`
  ? ParamWithOptionalRegExp extends `${infer Param}(${infer _})`
  ? ExtractRouteOptionalParam<Param, U> & ExtractRouteParams<Rest, U>
  : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U> & ExtractRouteParams<Rest, U>
  : T extends `${infer _}:${infer ParamWithOptionalRegExp}`
  ? ParamWithOptionalRegExp extends `${infer Param}(${infer _})`
  ? ExtractRouteOptionalParam<Param, U>
  : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U>
  : unknown;
