import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ServiceLoader } from 'components/common/Loading';
import { Layout } from 'components/Layout';
import { RoutePaths } from './RoutePaths';
import { ProtectedRoute } from './ProtectedRoute';

const HomeLayout = lazy(() => import('components/Pages/Home/HomeLayout'));
const CreatePublicationLayout = lazy(() => import('components/Pages/CreatePublication/CreatePublicationLayout'));
const TranslatePublicationLayout = lazy(() => import('components/Pages/TranslatePublication/TranslatePublicationLayout'));

export const AppRoutes = () => (
  <Layout>
    <Suspense fallback={<div className="d-flex justify-content-center"><ServiceLoader /></div>}>
      <Routes>
        <Route
          path={RoutePaths.Home.route}
          element={
            <ProtectedRoute>
              <HomeLayout />
            </ProtectedRoute>
          }
        />
        <Route
          path={RoutePaths.CreatePublication.route}
          element={
            <ProtectedRoute>
              <CreatePublicationLayout />
            </ProtectedRoute>
          }
        />
        <Route
          path={RoutePaths.TranslatePublication.route}
          element={
            <ProtectedRoute>
              <TranslatePublicationLayout />
            </ProtectedRoute>
          }
        />
        <Route
          path={RoutePaths.Home.Preview.route}
          element={
            <ProtectedRoute>
              <HomeLayout />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Suspense>
  </Layout>
);
