import React from 'react';
import { Environment, getEnvironment } from 'config/constants';
import { FC } from 'react';

export const NonProduction: FC<{ children: React.ReactNode }> = ({ children }) => {
  const environment = getEnvironment();
  if (environment === Environment.Production) {
    return null;
  }
  return (
    <>{children}</>
  );
};