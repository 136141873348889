import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import calendar from 'dayjs/plugin/calendar';
import isToday from 'dayjs/plugin/isToday';
import duration from 'dayjs/plugin/duration';
import { format, isSameDay, formatDistanceToNow } from 'date-fns';
import { PublicationSummary } from './ApiService';
export { Dayjs } from 'dayjs';

const defaultTimeZone = 'Europe/Paris';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(calendar);
dayjs.extend(isToday);
dayjs.extend(duration);

dayjs.tz.setDefault(defaultTimeZone);

const dateFormat = 'D MMM YYYY';
const timeFormat = 'h:mm A [(PT)]';
const timeSecFormat = 'h:mm:ss A [(PT)]';

export const dayjstz = (date: Date | string | number | null | undefined) => dayjs(date).tz();
export const dayjsutc = (date: Date | string | number | null | undefined) => dayjs(date).utc();

export const formatDatetime = (date: Date | string | number | null | undefined) => {
    return dayjstz(date).calendar(dayjstz(Date.now()), {
        sameDay: `[today at] ${timeFormat}`,
        nextDay: `[tomorrow at] ${timeFormat}`,
        nextWeek: `dddd [at] ${timeFormat}`,
        lastDay: `[yesterday at] ${timeFormat}`,
        lastWeek: `[last] dddd [at] ${timeFormat}`,
        sameElse: `${dateFormat} [at] ${timeFormat}`,
    });
};

export const formatYear = (date: Date | string | number | null | undefined) => dayjstz(date).format('YYYY');

export const formatMonth = (date: Date | string | number | null | undefined) => dayjstz(date).format('MMM');

export const formatDay = (date: Date | string | number | null | undefined) => dayjstz(date).format('D');

export const formatTime = (date: Date | string | number | null | undefined) => dayjstz(date).format(timeFormat);

export const formatTimeSec = (date: Date | string | number | null | undefined) => dayjstz(date).format(timeSecFormat);

export const formatDate = (date: Date | string | number | null | undefined) => dayjstz(date).format(dateFormat);

export const formatShortDatetime = (date: Date | string | number | null | undefined) => dayjstz(date).isToday() ? formatTime(date) : formatDate(date);

export const modificationDate = (publication: PublicationSummary) => {
  const date = publication.lastModificationDateUtc;
  return isSameDay(date, new Date())
    ? formatDistanceToNow(date, { addSuffix: true })
    : format(date, 'd MMM yyyy');
};